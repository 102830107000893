<template>
  <div id="database">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
#database {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #ebf2f8;
  min-height: 100vh;
}
</style>
